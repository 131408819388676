<template>
  <div>
   <MoviePage/>
  </div>
</template>

<script>
import MoviePage from './components/MoviePage.vue'
export default {
  name: 'App',
  components: {

  
   MoviePage
  }
}
</script>
