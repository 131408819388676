
<template>
  <div class="movie">
    <h1>Movies Show</h1>
   
    <ul v-if="items">
   <div class="container">
      <li v-for="item in items" :key="item.id" style="list-style: none;">
        <!-- <div v-if()></div> -->
         
           <div class="img">
          
              <img :src='item.poster_path '/>  
          
              <p>{{ item._id }}</p>  
              <p>{{ item.title }}</p>  
            </div>
         
      </li>
    </div>
    </ul>
    <p v-else>Loading...</p>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      title: '',
      items: []
    };
  },
  mounted() {
    const data = {
        method: "GET",
        url: 'https://movies-api14.p.rapidapi.com/shows',
  headers: {
    'x-rapidapi-key': 'c03673c83emshc797d8fd752e6b1p1e251ajsnfd22f381db55',
    'x-rapidapi-host': 'movies-api14.p.rapidapi.com'
         },
      };
      axios.request(data).then((response) => {
        console.log(response);
        this.items = response.data.movies;        
      })
      .catch(error => {
        // Handle any errors that occurred during the API request
        console.log(error);
        
      });
  },
};
</script> 
<style scoped>
img{
  width: 200px;  
}
.container{
  flex: 1;
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  width: 100%;
}


</style>

